'use client'

import { useState, useRef, useEffect, CSSProperties } from 'react'
import Image from 'next/image'
import useLanguageSwitcher from '../widgets/settings/useLanguageSwitcher'
import { Locale } from '@/i18n/routing'

interface Props {
  hidePc?: boolean
  hideMb?: boolean
}

const LanguageSelector = ({ hideMb = false, hidePc = false }: Props) => {
  const { changeLanguage, currentLocale } = useLanguageSwitcher()
  const [isOpen, setIsOpen] = useState(false)
  const selectorRef = useRef<HTMLDivElement>(null)

  const [englishFlag, setEnglishFlag] = useState('/img/flags/us-flag.svg')

  useEffect(() => {
    // Determina si el usuario está en Europa según su zona horaria
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const inEurope = timeZone.includes('Europe')
    // Si está en Europa => en-flag (UK), en caso contrario => us-flag
    setEnglishFlag(inEurope ? '/img/flags/en-flag.svg' : '/img/flags/us-flag.svg')
  }, [])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleLanguageChange = (lng: Locale) => {
    changeLanguage(lng)
    setIsOpen(false)
  }

  // Cerrar el dropdown al hacer clic fuera
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Rutas "base" de las banderas
  const flags = {
    es: '/img/flags/es-flag.svg',
    // OJO: la ruta para el inglés se obtiene de englishFlag
    en: englishFlag
  }

  return (
    <div
      style={styles.languageSelector}
      ref={selectorRef}
      className={hidePc ? 'hide-pc-1000' : hideMb ? 'hide-mb' : ''}
    >
      <button
        style={styles.flagButton}
        onClick={toggleDropdown}
        aria-haspopup='true'
        aria-expanded={isOpen}
      >
        <Image
          src={flags[currentLocale]}
          alt={currentLocale === 'es' ? 'Spanish Flag' : 'English Flag'}
          width={40}
          height={40}
          style={styles.flagImageLarge}
        />
      </button>
      {isOpen && (
        <div style={styles.dropdown}>
          {/* Botón inglés */}
          <button
            style={styles.dropdownItem}
            onClick={() => handleLanguageChange('en')}
            onMouseOut={(e) => {
              (e.currentTarget as HTMLButtonElement).style.backgroundColor = 'none'
            }}
          >
            <Image
              src={englishFlag} // aquí usamos la ruta dinámica
              alt='English Flag'
              width={24}
              height={24}
              style={styles.flagImage}
            />
            <span style={{ marginLeft: '10px' }}>English</span>
          </button>

          {/* Botón español */}
          <button
            style={styles.dropdownItem}
            onClick={() => handleLanguageChange('es')}
            onMouseOut={(e) => {
              (e.currentTarget as HTMLButtonElement).style.backgroundColor = 'none'
            }}
          >
            <Image
              src={flags.es}
              alt='Spanish Flag'
              width={24}
              height={24}
              style={styles.flagImage}
            />
            <span style={{ marginLeft: '10px' }}>Español</span>
          </button>
        </div>
      )}
      {isOpen && (
        <div
          style={styles.stopper}
          onClick={() => setIsOpen(false)}
          aria-hidden='true'
        />
      )}
    </div>
  )
}

export default LanguageSelector

// Estilos en línea
const styles = {
  languageSelector: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  } as CSSProperties,
  flagButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    overflow: 'hidden',
    width: '40px',
    height: '40px',
    transition: 'transform 0.3s ease'
  } as CSSProperties,
  dropdown: {
    position: 'absolute',
    top: '50px',
    left: -50,
    backgroundColor: '#ffffff',
    border: '1px solid #ddd',
    borderRadius: '3px',
    zIndex: 1000,
    width: '150px',
    display: 'flex',
    flexDirection: 'column'
  } as CSSProperties,
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    background: 'none',
    border: 'none',
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
    fontFamily: 'Comfortaa, sans-serif',
    fontSize: '16px',
    transition: 'background-color 0.2s ease'
  } as CSSProperties,
  stopper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 999
  } as CSSProperties,
  flagImage: {
    borderRadius: '50%',
    objectFit: 'cover',
    width: '22px',
    height: '22px'
  } as CSSProperties,
  flagImageLarge: {
    objectFit: 'cover',
    borderRadius: '50%',
    width: '25px',
    height: '25px'
  } as CSSProperties
}
